import { useEffect, FC, Fragment } from 'react';
import { RootState } from '@src/redux/slices/rootReducer'
import {useRouter} from 'next/router'
import { useSelector } from 'react-redux'


const AutoRedirectTop: FC = ({children}) => {
  const auth = useSelector((state: RootState) => {
    return state.auth
  })
  const router = useRouter()

  useEffect(() => {
    if (!auth.isLoading && auth.isLogin) {
      router.replace('/')
    }
  })

  return (
    <Fragment>
      {!auth.isLogin ? children : null}
    </Fragment>
  )
}

export default AutoRedirectTop